


























































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Ref } from 'vue-property-decorator'
import GenericTablePaginationJira from '../../components/_base/BcDataTable/GenericTablePaginationJira.vue'
import PaginationInterface from '../../typings/PaginationInterface'
import RequestApi from '../../services/RequestApi'
import { mapGetters } from 'vuex'
import { isRequired } from '@bluecorner/shared'
import {
  BreadCrumbType,
  SupportRequestDetail,
  AttachmentResponseInterface
} from '@/typings/ApiTypings/JiraSupportInterface'
import VForm from '@/typings/Vuetify/VForm'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'
import BaseApi from '@/services/BaseApi'

@Component({
  components: {
    GenericTablePaginationJira
  },
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo' })
  },
  filters: {
    filterAuthor(val: string) {
      if (val === 'System Administrator') {
        val = 'Blue Corner Support'
        return val
      }
      return val
    },
    statusLabel (val: string) {
      if (val === 'Gedaan') {
        return 'Done'
      }
      return val
    }
  }
})
export default class MyRequests extends Vue {
  @Prop({ type: String, default: '0' }) readonly id!: string
  @Prop({ type: String, default: '' }) readonly dateIn!: string
  @Ref() readonly form!: VForm

  itemsBrdcrmb: BreadCrumbType[] = []

  payload = {
    body: ''
  }

  isAdd = false
  isLoadComment = false
  loading = false
  dataRequest: SupportRequestDetail | null = null

  error = false
  errorMessages = ''
  errorProvider = false
  errorProviderMessages = ''
  isFormValid = true

  rulesText: Array<any> = [isRequired()]

  mounted() {
    this.loadIssueDetails()
  }

  submit() {
    if (this.form.validate()) {
      this.addComment()
    }
  }

  async addComment() {
    try {
      this.isLoadComment = true
      await new RequestApi().createComment(this.id, this.payload)
      this.isLoadComment = false
      this.resetErrors()
      this.payload.body = ''
      this.loadIssueDetails()
      this.isAdd = false
    } catch (err) {
      console.log(err)
    }
  }

  async downloadAttachment(id: string) {
    try {
      const data = (await new BaseApi('').getStreamApi({
        url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/jira/BCO/attachment/download/${id}`
      })) as AttachmentResponseInterface
      const url = window.URL.createObjectURL(new Blob([data.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', data.headers.filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.log(error)
    }
  }

  initBreadCrumb() {
    this.itemsBrdcrmb = [
      {
        text: 'Requests',
        disabled: false,
        href: 'myRequests',
        to: { name: 'myRequests' },
        last: false
      },
      {
        text: this.dataRequest?.sourcereference,
        disabled: true,
        href: 'breadcrumbs_link_1',
        last: true
      }
    ]
  }

  async loadIssueDetails() {
    try {
      this.loading = true
      const data = (await new RequestApi().getIssue(
        this.id
      )) as DefaultResponseInterface<SupportRequestDetail>
      this.dataRequest = data.result.data
      this.loading = false
      this.initBreadCrumb()
    } catch (err) {}
  }

  resetErrors() {
    this.error = false
    this.errorMessages = ''

    this.errorProvider = false
    this.errorProviderMessages = ''
  }

  @Watch('id')
  idOnChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.loadIssueDetails()
    }
  }
}
